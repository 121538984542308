import React from "react"
import {graphql, useStaticQuery} from 'gatsby'
import {Link} from 'gatsby'
import Layout from '../components/layout'
import Head from '../components/head'
import Img from 'gatsby-image'
import indexStyles from './index.module.css'
import Frontend from '../../static/icons/frontend.svg'
import Backend from '../../static/icons/backend.svg'
import '../styles/index.css'

export default function Index() {

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "images/foto-perfil-resized.jpg"}) {
        id
        childImageSharp {
          fixed(width: 200, height: 200, quality: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      allContentfulProject(limit: 9, sort: {fields: order}) {
        edges {
          node {
            title
            description
            slug
            thumbnail {
              title
              fixed(height: 450, width: 600, quality: 80, resizingBehavior: THUMB) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const sources = [{...data.file.childImageSharp.fixed}]

  const posts = data.allContentfulProject.edges.map(edge => {
    return (
      <div className='col col-md-6 col-lg-4' key={edge.node.slug}>
        <figure style={{borderStyle: 'solid', borderWidth: '1px', borderColor: '#E6ECF8'}} className={indexStyles.projectsThumbnailContainer}>
          <img src={edge.node.thumbnail.fixed.src} alt={edge.node.thumbnail.title} style={{maxWidth:'100%'}} />
          <div className={indexStyles.projectsOverlay}>
            <div>
              {edge.node.description}
            </div>
            <Link className={'link-button'} to={`/proyectos/${edge.node.slug}`}>
              Ver Detalles
            </Link>
          </div>
        </figure>
        <div style={{fontSize: '1.1rem', marginTop: '1rem', color: '#1678FF', cursor: 'pointer', textUnderlineOffset: '1px'}}>
          <Link to={`/proyectos/${edge.node.slug}`}>
            <span style={{color: '#1678FF', textDecoration: 'underline'}}>
              {edge.node.title}
            </span>
          </Link>
        </div>
      </div>
    )
  })

  return (
    <Layout>
      <Head title={'Inicio'} />
      <div className={indexStyles.hero}>
        <div className='container'>
          <h1 className={indexStyles.heroTitle}>Desarrollador web Full-Stack</h1>
          <p className={`${indexStyles.subtitle} ${indexStyles.darkColor}`}>
            Diseño y programo soluciones. Estoy comprometido con la satisfacción del cliente.
          </p>
          <Img className={indexStyles.circularImage} fixed={sources} alt='foto de Eduardo Ayora' />
        </div>
      </div>
      <section className={indexStyles.mediumSection}>
        <div className='container'>
          <h2 className={indexStyles.title}>Hola, soy Eduardo</h2>
          <p className={indexStyles.subtitle}>
            Me describo como una persona entusiasmada en la programación así como en utilizar buenas 
            prácticas, e investigativa de las tecnologías actuales para lograr una aplicación 
            de mayor calidad.
          </p>
        </div>
      </section>
      <section className={indexStyles.skills}>
        <div className='container'>
          <div className={indexStyles.box}>
            <div className={`columns ${indexStyles.boxColumns}`}>
              <div className='col col-md-6'>
                <div className={indexStyles.skillsIconContainer}>
                  <Frontend className={indexStyles.skillsIcon} />
                </div>
                <h2 className={indexStyles.title}>
                  Front-end
                </h2>
                <p className={`${indexStyles.skillsPrimaryText} ${indexStyles.skillsText}`}>
                  Lenguajes:
                </p>
                <p className={`${indexStyles.skillsText} ${indexStyles.skillsMarginBottom}`}>
                  Javascript, TypeScript, Html, Css, Sass
                </p>
                <p className={`${indexStyles.skillsPrimaryText} ${indexStyles.skillsText}`}>
                  Herramientas de desarrollo:
                </p>
                <ul className={indexStyles.skillsList}>
                  <li>React</li>
                  <li>Tailwind CSS</li>
                  <li>Bootstrap</li>
                  <li>Gatsby</li>
                  <li>Next.js</li>
                </ul>
              </div>
              <div className='col col-md-6'>
                <div className={indexStyles.skillsIconContainer}>
                  <Backend className={indexStyles.skillsIcon} />
                </div>
                <h2 className={indexStyles.title}>
                  Back-end
                </h2>
                <p className={`${indexStyles.skillsPrimaryText} ${indexStyles.skillsText}`}>
                  Lenguajes:
                </p>
                <p className={`${indexStyles.skillsText} ${indexStyles.skillsMarginBottom}`}>
                  JavaScript, TypeScript, Php, Python, Java
                </p>
                <p className={`${indexStyles.skillsPrimaryText} ${indexStyles.skillsText}`}>
                  Herramientas de desarrollo:
                </p>
                <ul className={indexStyles.skillsList}>
                  <li>Node</li>
                  <li>Express</li>
                  <li>Next.js</li>
                  <li>Firebase</li>
                  <li>Cloudflare Workers</li>
                  <li>Postgres</li>
                  <li>MongoDB</li>
                  <li>MySQL</li>
                  <li>Prisma ORM</li>
                  <li>Contentful CMS</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={indexStyles.projects}>
        <div className='container'>
          <h2 className={indexStyles.title}>Mis proyectos</h2>
          <p className={indexStyles.subtitle}>
            Aquí están algunos proyectos que he desarrollado recientemente
          </p>
          <div className={`columns ${indexStyles.projectsColumns}`}>
            {posts}
          </div>
          <Link className='link-button' to='/proyectos'>Ver más proyectos</Link>
        </div>
      </section>
      <section className={indexStyles.callToAction}>
        <div className='container'>
          <div className={`${indexStyles.box} ${indexStyles.darkBox}`}>
            <div className={`columns ${indexStyles.callToActionColumns}`}>
              <div className='col col-md-4'>
                <h2 className={indexStyles.title}>Iniciar un proyecto</h2>
              </div>
              <div className='col col-md-4'>
                <p className={indexStyles.subtitle}>¿Interesado en trabajar juntos?</p>
              </div>
              <div className='col col-md-4'>
                <Link className={`link-button ${indexStyles.whiteText}`} to='/contacto'>Escríbeme</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
